import './App.css';
import React, {Suspense} from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Loading from "./components/pages/Loading";
import Home from "./components/pages/Home"
import AboutUs from './components/pages/AboutUs';
import ContactUs from './components/pages/ContactUs';
import Giving from './components/pages/Giving';
import Gallery from './components/pages/Gallery';
import Blueprint from './components/pages/Blueprint';
import Contributors from './components/pages/Contributors';
import { Analytics } from '@vercel/analytics/react';

function App() {
  return (
    <React.Fragment>
      <Analytics/>
      <Suspense fallback={<Loading/>}/>
      <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home/>}/>
            <Route path="/about-us" element={<AboutUs/>}/>
            <Route path="/contact-us" element={<ContactUs/>}/>
            <Route path="/contributors" element={<Contributors/>}/>
            <Route path="/blueprint" element={<Blueprint/>}/>
            <Route path="/gallery" element={<Gallery/>}/>
            <Route path="/giving" element={<Giving/>}/>
            <Route path="*" element={<Home/>}/>
          </Routes>
      </BrowserRouter>
    </React.Fragment>

  );
}

export default App;
