import React from "react";
import Flex from "../ui/Flex";
import Header from "../ui/Header";
import Footer from "../ui/Footer";
import { colors, fonts } from "../ui/theme/theme";
import Text from "../ui/Text";
import Image from "../ui/Image";
import duck from "../../assets/images/duckLong.JPG";
import styled from "styled-components";
import {useNavigate} from "react-router-dom";


export default function Blueprint() {
    const navigate = useNavigate();
    return (
        <Flex {...styles.Container}>
            <Header/>
            <Text {...styles.TitleText}>Blueprint</Text>
            <Image src={duck} {...styles.DuckImage}/>
            <Flex {...styles.TextContainer}>
                <Text {...styles.subTitleText}> <BoldSpan {...styles.Bold}>Blueprint Overview</BoldSpan></Text>
                <Text {...styles.paragraphText}>
                    <BoldSpan {...styles.Bold}>Module #1: "Science Based Nutrition”</BoldSpan> Presents an evidence-based approach to the fundamental principles of nutrition, with a focus on personalized strategies designed to enhance long-term well-being and optimize health. 
                </Text>
                <Text {...styles.paragraphText}>
                    <BoldSpan {...styles.Bold}>Module #2: “Blue Zones"</BoldSpan> Examines the principles underlying the longevity of Blue Zones populations, highlighting the holistic health practices that contribute to their balanced and thriving lives.
                </Text>
                <Text {...styles.paragraphText}>
                    <BoldSpan {...styles.Bold}>Module #3: “Tailored Meal Plan”</BoldSpan> A customized meal plan that adapts to individual dietary needs, preferences, and nutritional goals for a sustainable approach to eating.
                </Text>
                <Text {...styles.paragraphText}>
                    <BoldSpan {...styles.Bold}>Module #4: “Sustainable Food Sourcing”</BoldSpan> Equips clients with the knowledge and tools to align food sourcing with ethical and environmentally responsible practices throughout the supply chain, fostering a more sustainable and compassionate food system.
                </Text>
                <Text {...styles.paragraphText}>
                    <BoldSpan {...styles.Bold}>Module #5: “Kitchen & Pantry Organization”</BoldSpan> Offers tailored solutions to streamline kitchen systems, from ergonomic design to inventory management and maintenance, ensuring both practicality, and eco-conscious practices.
                </Text>
                <Text {...styles.paragraphText}>
                    <BoldSpan {...styles.Bold}>Module #6: “Culinary Fundamentals”</BoldSpan> Comprehensive theoretical understanding of foundational aspects of culinary arts, focusing on cooking methods and execution of essential dietary staples.
                </Text>
                <Text {...styles.paragraphText}>
                    <BoldSpan {...styles.Bold}>Module #7: “Cooking Classes”</BoldSpan> Focused on honing essential kitchen skills, encompassing everything from knife handling and flavor development to waste reduction and the practice of mindful eating.
                </Text>
                <Text {...styles.paragraphText}>
                    This multifaceted blueprint consists of <BoldSpan {...styles.Bold}>45 sessions</BoldSpan> over the course of <BoldSpan {...styles.Bold}>one year</BoldSpan>, designed to ensure measurable and sustainable success.
                </Text>
                <Text {...styles.subTitleText} paddingBottom={"10px"}> <BoldSpan {...styles.Bold}>Blueprint Outline</BoldSpan></Text>
                <Text {...styles.paragraphText}>
                    <ul>
                        <ListItem><BoldSpan {...styles.Bold}>Months 1-3:</BoldSpan> Twice per week to establish a strong foundation and momentum. During this time, all modules of the comprehensive blueprint will be taught in full and implemented (24 sessions).</ListItem>
                        <ListItem><BoldSpan {...styles.Bold}>Months 4-6:</BoldSpan> Once per week for skill refinement and adjustments (12 sessions).</ListItem>
                        <ListItem><BoldSpan {...styles.Bold}>Months 7-9:</BoldSpan> Twice per month to refine and optimize your progress (6 sessions).</ListItem>
                        <ListItem><BoldSpan {...styles.Bold}>Months 10-12:</BoldSpan> Once per month to maintain support and ensure long-term sustainability (3 sessions).</ListItem>
                    </ul>
                </Text>
                <Text {...styles.subTitleText}> <BoldSpan {...styles.Bold}>Session Format</BoldSpan></Text>
                <Text {...styles.paragraphText}>
                    The session format—whether in-person or virtual—will be determined at my discretion, based on the specific topic, your goals, and individual needs. During the first three months, all meetings will be held in person to establish a strong foundation and ensure personalized, hands-on guidance. Subsequent formats will be tailored for flexibility and effectiveness. Any changes to the session format will be communicated in advance, ensuring your preferences are considered while aligning with a structured schedule that supports your objectives.               
                </Text>
                <Text {...styles.subTitleText}> <BoldSpan {...styles.Bold}>Session Duration</BoldSpan></Text>
                <Text {...styles.paragraphText}>
                    For the first three months, we will meet twice per week. Each week will include one <BoldSpan {...styles.Bold}>one-hour</BoldSpan> session, focused on high-impact content designed to drive tangible progress, and one <BoldSpan {...styles.Bold}>two-hour</BoldSpan> session, providing extended time for deeper exploration and application. These sessions are structured for maximum efficiency, ensuring every minute is dedicated to in-depth, results-oriented work. Clients are expected to arrive on time, prepared, and fully engaged to maximize the value of each session. After the first three months, all sessions will transition to the standard one-hour format for ongoing progress reviews and support.               
                </Text>
                <Text {...styles.subTitleText}> <BoldSpan {...styles.Bold}>Session Commitment</BoldSpan></Text>
                <Text {...styles.paragraphText}>
                    You are responsible for prioritizing and completing all scheduled sessions within the designated timeframe for each quarter. The number of sessions allocated per quarter is fixed and cannot be adjusted. No additional sessions will be provided beyond those outlined in the blueprint. Please note that the luxury service period will conclude within one year, with no exceptions.
                </Text>
                <Text {...styles.subTitleText}> <BoldSpan {...styles.Bold}>Program Availability</BoldSpan></Text>
                <Text {...styles.paragraphText} paddingBottom={'0px'}>
                    Choose from the following quarterly start dates:
                </Text>
                <Text {...styles.paragraphText} paddingBottom={'10px'}>
                    <ul>
                        <li>January 15th</li>
                        <li>April 15th</li>
                        <li>July 15th</li>
                    </ul>
                </Text>
                <Text {...styles.paragraphText}>
                    To ensure each client receives individualized support, I work with a maximum of four clients per quarter. If your preferred start date is fully booked, you may request to be added to the waitlist.                </Text>
                <Text {...styles.subTitleText}> <BoldSpan {...styles.Bold}>Investment and Payment</BoldSpan></Text>
                <Text {...styles.paragraphText}>
                    Each session is priced at $370, offering exceptional value for the tailored guidance and expertise you’ll receive. To begin, a 50% deposit is required at enrollment to secure your spot in this exclusive, luxury service. The remaining balance is due at the start of your program, allowing you to focus fully on your growth with confidence and clarity. This investment is a pivotal step toward achieving a sustainable and enriching culinary lifestyle.
                </Text>
                <Text {...styles.subTitleText}> <BoldSpan {...styles.Bold}>Ready to begin?</BoldSpan></Text>
                <Text {...styles.paragraphText}>
                    Visit the <LinkSpan onClick={() => navigate("/contact-us")}>Contact Us</LinkSpan> page to schedule your introductory meeting and embark on this transformative journey. I look forward to partnering with you :)
                </Text>
            </Flex>
            <Footer/>
        </Flex>
    );
}

const ListItem = styled.li`
    margin-bottom: 25px; /* Adds 10px spacing below each list item */
    
    &:last-child {
        margin-bottom: 0; /* Removes margin for the last item */
    }
`;

const BoldSpan = React.memo(styled.span`
    font-family: ${fonts.cormorandSemiblold}
`);

const LinkSpan = React.memo(styled.a`
    text-decoration: underline 1px;
    cursor: pointer;
    color: rgb(77,87,64);
    &:visited {
        color: inherit;
    }
`);

const styles = {
    Container: {
        width: "100vw",
        minHeight: "100vh",
        alignItems: "center",
        justifyContent: "flex-start",
        flexDirection: "column",
    },
    TitleText: {
        fontFamily: fonts.cormorandMedium,
        fontSize: "40px",
        color: colors.olive,
        paddingBottom: "60px"
    },
    DuckImage: {
        width: "100%"
    },
    TextContainer: {
        paddingTop: "35px",
        paddingLeft: ["30px","60px"],
        paddingRight: ["30px","60px"],
        maxWidth: "900px",
        flexDirection: "column",

    },
    paragraphText: {
        fontFamily: fonts.cormorand,
        fontSize: "20px",
        color: colors.olive,
        paddingBottom: "30px",
        lineHeight: 1.5,
        textAlign: "justify",
    },
    subTitleText: {
        fontSize: "26px",
        color: colors.olive,
        paddingBottom: "16px",
        lineHeight: 1.5,
        fontFamily: fonts.cormorand,
    },
    Bold: {
        fontFamily: fonts.cormorandBold,
    }
}