import React, { useEffect, useState } from "react";
import Flex from "../ui/Flex";
import Image from "../ui/Image";
import Text from "../ui/Text";
import Header from "../ui/Header";
import Footer from "../ui/Footer";
import { colors, fonts } from "../ui/theme/theme";

import crab from "../../assets/images/CrabCropped.jpg"
import mushrooms from "../../assets/images/oyster.webp"
import lsfLogo from "../../assets/images/LeSavoirFareLOGOLong.png";
import {useNavigate} from "react-router-dom";
import zeroFoodPrint from "../../assets/images/zero_foodprint.png";


export default function Home() {
    const navigate = useNavigate();
    const [mobileHeroHeight, setMobileHeroHeight] = useState(`${window.innerHeight}px`);
    const [mobileRightSideContainerHeight, setMobileRightSideContainerHeight] = useState(`${window.innerHeight - 135}px`);

    useEffect(() => {
        const handleResize = () => {
            setMobileHeroHeight(`${window.innerHeight}px`);
            setMobileRightSideContainerHeight(`${window.innerHeight - 135}px`);
        };

        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return (
        <Flex {...styles.Container}>
            <Header/>
                <Flex {...styles.HeroContainer} {...{ minHeight: [mobileHeroHeight, "950px"] }} >
                    <Image src={crab} {...styles.CrabImage}/>
                    <Flex {...styles.RightSideContainer}  {...{ minHeight: [mobileRightSideContainerHeight, "950px"] }}>
                        <Image src={mushrooms} {...styles.MushroomImage} />
                        <Flex {...styles.HeroTextContainer} >
                            <Text {...styles.HeroTitleText}>
                            Craft Your Health, One Meal at a Time.
                            </Text>
                            <Text
                                onClick={() => {
                                    navigate("/about-us");
                                }} 
                                {...styles.HeroSubtext}
                            >
                                Learn more
                            </Text>
                        </Flex>
                    </Flex>

                </Flex>
                <Flex {...styles.BottomContainer}>
                    <Flex {...styles.DrapeContainer}>
                        <Flex {...styles.DrapeTextContainer}>
                            <Flex {...styles.DrapeTextSpaceContainer}>
                                <Image src={lsfLogo} {...styles.LsfDrapeLogoImage}/>
                                <Text {...styles.paragraphText}></Text>
                                <Text {...styles.paragraphText}>
                                    "Savoir Faire" is a French expression that originated in the 19th century, directly translating to "know-how." It has long been used across various industries to represent creativity, innovation, meticulous attention to detail, and luxury—qualities that define high-quality craftsmanship. By replacing the French term 'faire' with the English 'fare,' which refers to a wide variety of foods, the phrase underscores expertise in preparing a diverse range of cuisines.
                                </Text>
                                <Text {...styles.paragraphText}>
                                    The mission is to empower individuals with the knowledge and skills to implement sustainable meal plans that align with their dietary needs and lifestyles, while emphasizing evidence-based practices and fostering long-term well-being.
                                </Text>
                                <Text {...styles.paragraphText}>
                                    Le Savoir Fare conscientiously involves its clients in the agricultural climate solution movement, an initiative led by the non-profit organization Zero FoodPrint, which manages the Restore California project. As an active member of Zero FoodPrint, LSF participates by applying a 1% surcharge to clients, helping to fund renewable farming practices aimed at growing food with a focus on replenishing the soil.
                                </Text>
                                <Text {...styles.paragraphText}>
                                    Supporting soil health will help absorb carbon pollution from the atmosphere, improve water quality, and protect against drought, all while cultivating nutrient-rich foods. This method is proven to be one of the most impactful initiatives yet toward solving global warming.
                                </Text>
                            </Flex>
                            <Image src={zeroFoodPrint} {...styles.zeroFoodprintImage}/>
                        </Flex>
                    </Flex>

                </Flex>
            <Footer/>
        </Flex>
    );
}

const styles = {
    Container: {
        width: "100vw",
        minHeight: "100vh",
        alignItems: "center",
        justifyContent: "flex-start",
        flexDirection: "column",
    },
    HeroContainer: {
        width: "100vw",
        zIndex: 0,
        height: [null, "950px"],
        flexDirection: ["column-reverse", "row"]
    },
    CrabImage: {
        marginTop: ["0px", "-210px"],
        width: ["100vw", "50vw"],
        height: ["100%", "1160px"],
    },
    RightSideContainer: {
        width: ["100vw", "50vw"],
        height: [null, "1160px"],
        marginTop: ["0px", "-210px"],
    },
    MushroomImage: {
        width: ["100vw", "50vw"],
        height: ["100vh", "1160px"],
        position: ['absolute', null],
        top: [0, null],
    },
    HeroTextContainer: {
        width: ["350px", "50vw"],
        position: "absolute",
        right: ["50%", 0],
        marginRight: ["-175px", 0],
        top: ["240px", "400px"],
        zIndex: 1,
        flexDirection: "column",
        alignItems: "flex-start",
        jusifyContent: "center",
        paddingLeft: "50px"
    },
    HeroTitleText: {
        color: colors.blueTeal,
        fontSize: "35px",
        fontFamily: fonts.cormorandBlold,
    },
    HeroSubtext: {
        color: colors.blueTeal,
        fontSize: "20px",
        fontFamily: fonts.cormorandLightItalic,
        paddingTop: "10px",
        cursor: "pointer",
    },
    BottomContainer: {
        width: "100vw",
        overflow: "clip",
        height: [null, "1000px"],
        position: "relative",
        zIndex: 0,
        flexDirection: ["column-reverse", "row"]
    },
    DrapeContainer: {
        width: ["100vw","100vw"],
        paddingTop: ['60px', null],
        backgroundColor: colors.beigeBackground,
        alignItems: "center",
        justifyContent: "center",
    },
    DrapeTextContainer: {
        alignItems: "center",
        justifyContent: "center",
        width: ["90vw","90vw"],
        maxWidth: "800px",
        paddingBottom: "60px",
        flexDirection: "column",
        zIndex: 1,
    },
    DrapeTextSpaceContainer: {
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-between",
        position: "relative",
    },
    LsfDrapeLogoImage: {
        width: ["300px", "557px"],
        paddingBottom: "20px",
    },
    DrapeText: {
        fontSize: ["30px", "40px"],
        fontFamily: fonts.cormorandMedium,
        color: colors.beigeBackground,
    },
    paragraphText: {
        fontFamily: fonts.cormorand,
        fontSize: "20px",
        color: colors.olive,
        paddingBottom: "35px",
        lineHeight: 1.5,
        textAlign: "justify",

    },
    zeroFoodprintImage: {
        width: "92px",
        right: "50%",
        bottom: 65,
        color: colors.olive,
    },
}