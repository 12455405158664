import React from "react";
import Flex from "../ui/Flex";
import Header from "../ui/Header";
import Footer from "../ui/Footer";
import Text from "../ui/Text";
import { colors, fonts } from "../ui/theme/theme";
import Image from "../ui/Image";
import dinnerTable from "../../assets/images/dinnerTable.webp"

export default function Giving() {
    return (
        <Flex {...styles.Container}>
            <Header/>
            <Text {...styles.TitleText}>Giving</Text>
            <Text {...styles.SubText}>Aiming to restore the climate, one meal at a time</Text>
            <Flex {...styles.Body}>
                <Flex {...styles.ContentContainer}>
                    <Image src={dinnerTable} {...styles.DinnerTableImage}/>
                    <Flex {...styles.RightContainer}>
                        <Text {...styles.GivingTitleText}>Let’s eat our way out of the climate crisis!</Text>
                        <Text {...styles.GivingParagraphText}>
                            Founded by a chef, Le Savoir Fare recognizes the distinctive relationship that culinary professionals have with the world. We tend to communities and families, perpetually seeking a connection with the people that we nourish. Simultaneously, we maintain a deep, intrinsic bond with nature and the resources it provides. This dual commitment drives us to pursue practices that promote the well-being of people, animals, and the environment.
                        </Text>
                        <Text {...styles.GivingParagraphText}>
                            Born in the aftermath of the COVID-19 pandemic and the devastating Lightning Complex Fires of 2020—exacerbated by global warming—Le Savoir Fare aims to address the environmental challenges of our time. These fires alone scorched nearly 400,000 acres in California, resulting in hazardous air quality and ominous red skies, highlighting the urgency of adopting not only sustainable but also regenerative practices.
                        </Text>
                        <Text {...styles.GivingParagraphText}>
                            We invite you to join us in advocating for responsible farming and production methods that prioritize the health of both our communities and our planet.  Stand with us in supporting our mission and organizations like Zero FoodPrint, the Regenerative Organic Alliance, and Le Savoir Fare as we lead efforts toward a more sustainable and equitable future.                      
                        </Text>
                        <Flex {...styles.ButtonRow}>
                            <Flex {...styles.Button} onClick={() => {
                                window.open("https://www.zerofoodprint.org/")
                            }}>
                                <Text {...styles.ButtonText}>Zero FoodPrint</Text>
                            </Flex>
                            <Flex {...styles.Button} onClick={() => {
                                window.open("https://regenorganic.org/")
                            }}>
                                <Text {...styles.ButtonText}>Regenerative Organic Alliance</Text>
                            </Flex>
                            <Flex {...styles.Button} onClick={() => {
                                window.open("/contact-us")
                            }}>
                                <Text {...styles.ButtonText}>Le Savoir Fare</Text>
                            </Flex>
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>
            <Footer/>
        </Flex>
    );
}
const styles = {
    Container: {
        width: "100vw",
        minHeight: "100vh",
        alignItems: "center",
        justifyContent: "flex-start",
        flexDirection: "column",
    },
    TitleText: {
        fontFamily: fonts.cormorandMedium,
        fontSize: "40px",
        color: colors.olive,
        paddingBottom: "30px"
    },
    SubText: {
        fontFamily: fonts.cormorand,
        fontSize: "18px",
        lineHeight: "24px",
        color: colors.olive,
        maxWidth: "750px",
        textAlign: "center",
        paddingBottom: "40px"
    },
    Body: {
        paddingLeft: ["0px", "40px"],
        paddingRight: ["0px", "40px"],
        width: "100vw",
    },
    ContentContainer: {
        backgroundColor: colors.beigeBackground,
        width: "100%",
        flexDirection: ["column-reverse", "row"],
        justifyContent: "flex-start"
    },
    DinnerTableImage: {
        width: ["100%", "50%"]
    },
    RightContainer: {
        width: ["100%", "50%"],
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        paddingTop: "45px",
    },
    GivingTitleText: {
        fontFamily: fonts.cormorandMedium,
        fontSize: ["20px", "25px"],
        color: colors.olive,
        paddingBottom: "70px",
        maxWidth: ["85vw", "425px"],
    },
    GivingParagraphText: {
        fontFamily: fonts.cormorand,
        fontSize: "18px",
        color: colors.olive,
        paddingBottom: "28px",
        maxWidth: ["85vw", "460px"],
        textAlign: "justify"
    },
    ButtonRow: {
        flexDirection: ["column", "row"],
        width: [null, "555px"],
        height: ["230px", null],
        alignItems: "center",
        justifyContent: "space-between",
        paddingTop: ["10px", "60px"],
        paddingBottom: ["60px", null]
    },
    ButtonText: {
        color: colors.beigeBackground,
        fontFamily: fonts.cormorand,
        fontSize: "18px",
    },
    Button: {
        backgroundColor: colors.olive,
        paddingRight: "15px",
        paddingLeft: "15px",
        paddingTop: "12px",
        paddingBottom: "12px",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
        hoverOpacity: "0.9"
    }

}